import React, { useCallback, useEffect, useMemo } from 'react';
import { LayoutContent } from 'components/layout-content';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useAppDispatch, useAppSelector } from 'store';
import { actionLoosingWeightLoadData } from './store';
import { createColumn, DataGridEmpty, DataGridLight } from 'components/data-grid-light';
import { GridModel } from './models';
import {
  selectLoosingDeltaExtremum,
  selectLoosingWeightsDataFiltered,
  selectLoosingWeightsStatuses,
} from './store/selectors';
import { NativeScroll } from 'components/native-scroll';
import { Loading } from 'AurionCR/components';
import { TemplateAge } from 'components/templates/template-age';
import { TemplateDate } from 'components/templates/template-date';
import { APP_FORMAT_DATE } from 'configs/const';
import { TemplateWeight } from 'components/templates/template-weight';
import { TemplateDeltaWeight } from 'components/templates/template-delta-weight';
import { TemplatePatientCard } from 'components/templates/template-patient-card';
import { DialogPatient } from 'components/dialog-patient';
import { useValueQuery } from 'hooks/use-value-query';
import { Filters } from './components/filters';
import { TemplatePhoneNumber } from 'components/templates/template-phone-number';
import { PermissionProtectorScreen } from 'components/permission-protector-screen';
import { useCurrentUser } from 'components/hooks';
import style from './index.module.scss';
import { Stack, StackProps } from 'components/stack';
import { APP_ROUTES } from 'configs/routes';

export * from './store';

interface DeltaInfoProps extends StackProps {
  title: string;
  value: number;
}
const DeltaInfo: React.FC<DeltaInfoProps> = ({ title, value, ...rest }) => {
  return (
    <Stack {...rest} spacing={1}>
      <Typography color={'textSecondary'} style={{ fontWeight: 'bold' }}>
        {title}:
      </Typography>
      <TemplateDeltaWeight value={value} />
    </Stack>
  );
};

const PageLosingWeight: React.FC = () => {
  const { t, tp } = useTranslate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actionLoosingWeightLoadData());
  }, [dispatch]);

  const queryPatient = useValueQuery<string>({ name: 'patient' });

  const rows = useAppSelector(selectLoosingWeightsDataFiltered);
  const { isLoading } = useAppSelector(selectLoosingWeightsStatuses);

  const factoryClickPatient = useCallback((row: GridModel) => {
    return () => {
      window.open(APP_ROUTES.PATIENT_WINDOW({ id: row.appIdentityUserID }).href, 'blank');
    };
  }, []);

  const columns = useMemo(
    () => [
      createColumn<GridModel, 'fullName'>({
        field: 'fullName',
        renderHeader: () => t('name'),
        renderColumn: ({ row }) => (
          <TemplatePatientCard
            onClick={factoryClickPatient(row)}
            fullName={row.fullName}
            isTLC={row.isTLC}
            userPatientProfileID={row.appIdentityUserID}
            isActive={row.isActive}
            onHold={row.onHold}
            onHoldReason={row.onHoldReason}
            doNotProlongTreatment={row.doNotProlongTreatment}
            slowIncreaseWeight={row.slowIncreaseWeight}
            dateOfBirth={row.dateOfBirth}
            changeDosageByDoctorApproval={row.changeDosageByDoctorApproval}
          />
        ),
      }),
      createColumn<GridModel, 'shortRemark'>({
        field: 'shortRemark',
      }),
      createColumn<GridModel, 'idNumber'>({
        field: 'idNumber',
      }),
      createColumn<GridModel, 'mobilePhone'>({
        field: 'mobilePhone',
        renderColumn: ({ row }) => <TemplatePhoneNumber value={row.mobilePhone} />,
      }),
      createColumn<GridModel, 'dateOfBirth'>({
        field: 'dateOfBirth',
        renderHeader: () => t('age'),
        renderColumn: ({ row }) => <TemplateAge value={row.dateOfBirth} />,
      }),
      createColumn<GridModel, 'subscription'>({
        field: 'subscription',
        renderHeader: () => t('subscription-start-date'),
        renderColumn: ({ row }) => (
          <TemplateDate date={row.subscription?.startDate} format={APP_FORMAT_DATE} />
        ),
      }),
      createColumn<GridModel, 'subscription'>({
        field: 'subscription',
        renderHeader: () => t('subscription-end-date'),
        renderColumn: ({ row }) => (
          <TemplateDate date={row.subscription?.endDate} format={APP_FORMAT_DATE} />
        ),
      }),
      createColumn<GridModel, 'weight'>({
        field: 'weight',
        renderHeader: () => t('start-weight'),
        renderColumn: ({ row }) => <TemplateWeight value={row.weight} />,
      }),
      createColumn<GridModel, 'maintenanceWeight'>({
        field: 'maintenanceWeight',
        renderHeader: () => t('maintenance-weight'),
        renderColumn: ({ row }) => <TemplateWeight value={row.maintenanceWeight} />,
      }),
      createColumn<GridModel, 'lastWeight'>({
        field: 'lastWeight',
        renderHeader: () => t('last-weight'),
        renderColumn: ({ row }) => <TemplateWeight value={row.lastWeight} />,
      }),
      createColumn<GridModel, 'delta'>({
        field: 'delta',
        renderColumn: ({ row }) => <TemplateDeltaWeight value={row.delta} />,
      }),
    ],
    [t, factoryClickPatient],
  );

  const onRefresh = useCallback(() => {
    dispatch(actionLoosingWeightLoadData());
  }, [dispatch]);

  const { min, max } = useAppSelector(selectLoosingDeltaExtremum);

  const isEmpty = rows.length === 0;

  return (
    <>
      <LayoutContent
        header={
          <Typography variant="h1" color="secondary">
            {t('losing-weight-report')}
          </Typography>
        }
        classes={{
          main: style.content,
        }}
      >
        <Filters />
        <NativeScroll>
          <DataGridLight columns={columns} rows={rows} />
          {isEmpty && <DataGridEmpty />}
          {isLoading && <Loading />}
        </NativeScroll>
        <Stack className={style.footer} justifyContent={'space-between'}>
          <Typography color={'textSecondary'} style={{ fontWeight: 'bold' }}>
            {tp('total-items', { count: rows.length })}
          </Typography>
          <Stack spacing={1}>
            <DeltaInfo title={t('delta-min')} value={min} />
            <DeltaInfo title={t('delta-max')} value={max} />
          </Stack>
        </Stack>
      </LayoutContent>
      {queryPatient.value && (
        <DialogPatient
          itemID={queryPatient.value}
          onClose={queryPatient.onClear}
          onFormHandle={onRefresh}
        />
      )}
    </>
  );
};
const PageLosingWeightProtector: React.FC = () => {
  const { $isAdmin } = useCurrentUser();
  return (
    <PermissionProtectorScreen isSafe={$isAdmin}>
      <PageLosingWeight />
    </PermissionProtectorScreen>
  );
};

export default PageLosingWeightProtector;
