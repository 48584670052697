import { MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import { TimeSlot } from 'utils/app-helpers';
import { CLINICAL_MEETING_PATIENT_OPTIONS } from '../helpers';

export interface EmployeeTimeSlot extends TimeSlot {
  isInClinic: boolean;
}

export interface IClinicalMeetingsState {
  init: boolean;
  loading: boolean;
  view: 'calendar' | 'list';

  editMeeting: Partial<IClinicalMeeting & { start: string; end: string; date?: string }> | null;
  confirmDeleteMeeting: IClinicalMeeting | null;

  smsMeetingID: string | null;

  employees: ICMEmployee[];
  employeesWorkLogs: { [appIdentityUserID: string]: EmployeeTimeSlot[] };
  meetingTypes: ICMMeetingType[];
  meetingSubjects: ICMMeetingSubject[];
  times: ITime[];

  calendar: ICalendarState;
  list: IListState;
}

export interface IClinicalMeeting {
  id: string;

  clinicalMeetingTypeID: string;
  clinicalMeetingSubjectID: string;

  meetingFromDateTime: string;
  meetingToDateTime: string;

  userPatientProfileID: string;
  userEmployeeProfileID: string;

  remarks: string;

  userPatientProfile: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    mobilePhone: string | null;
    shortRemark: string | null | undefined;
    isActive: boolean;
    isTLC: boolean;
    onHold: boolean;
    onHoldReason: string | undefined;
    doNotProlongTreatment: boolean;
    slowIncreaseWeight: boolean;
    changeDosageByDoctorApproval: boolean;
  };

  arriveToClinicDateTime: string | null;
  approveMeeting: boolean;
  completeSessionDateTime: string | null;

  isActive: boolean;

  userPatientProfileSessionID: string | null;
  notebook: null | Pick<Components.Schemas.Notebook, 'labelKey'>;
}

export interface IClinicalMeetingListItem extends IClinicalMeeting {
  subscriptions: { endDate: string; title: string }[];
  activities: number;
}

export interface IClinicalMeetingCalendarItem extends IClinicalMeeting {}

export interface ICMEmployee {
  appIdentityUserID: string;
  userPhoto: string;
  fullName: string;
  firstName: string;
  lastName: string;
  department: string;
  color: string;
}

export interface ICMMeetingType {
  id: string;
  title: string;
  color: string;
  icon: string;
  meetingTypeKey: MEETING_TYPE_KEY;
  isActive: boolean;
}
export interface ICMMeetingSubject {
  id: string;
  title: string;
  color: string;
  icon: string;
  isCanceledMeeting: boolean;
  isNotShowingUpMeeting: boolean;
}

export interface ICMDayViewItem {
  id: string;
  cellID: string;
  rows: number;
  maxRows: number;
  data: IClinicalMeetingCalendarItem;
}

interface SharedFilters {
  search: string;
  clinicalMeetingTypeID: string;
  clinicalMeetingSubjectIDs: string[];
  isActivePatient: CLINICAL_MEETING_PATIENT_OPTIONS;
}

export interface ICMDayViewItemResize extends ICMDayViewItem {
  startY: number;
}

export interface ITime {
  id: string;
  decimal: number;
}

interface IListState {
  isRefreshingActivities: boolean;
  // flags
  loading: boolean;
  // order
  orderField: string;
  orderDirection: string;
  // pagination
  page: number;
  pageSize: number;
  // filters
  filters: { date: Date[]; userEmployeeProfileID: string } & SharedFilters;
  // data
  data: IClinicalMeetingListItem[];
  dataCount: number;
  // trigger update scroll
  triggerScrollUpdate: string;
}

export interface ICalendarState {
  loading: boolean;
  filters: { date: Date } & SharedFilters;

  data: IClinicalMeetingCalendarItem[];
  dialogDayViewItems: ICMDayViewItem[] | null;
  resizeDayViewItem: ICMDayViewItemResize | null;
  startDragDayViewItem: number;
}

export enum CLINICAL_MEETING_ACTION_TYPES {
  RESET = 'CLINICAL_MEETINGS_RESET',
  MERGE = 'CLINICAL_MEETINGS_MERGE',
  VIEW_MERGE = 'CLINICAL_MEETINGS_VIEW_MERGE',
  FILTER_MERGE = 'CLINICAL_MEETINGS_FILTER_MERGE',
  SEND_SMS = 'CLINICAL_MEETINGS_SEND_SMS',
  SET_CALENDAR_EMPLOYEE_TIME_SLOTS = 'CLINICAL_MEETINGS_SET_CALENDAR_EMPLOYEE_TIME_SLOTS',
  // saga
  INIT = 'CLINICAL_MEETING_INIT',
  REFRESH = 'CLINICAL_MEETINGS_REFRESH',
  REFRESH_ACTIVITIES = 'CLINICAL_MEETINGS_REFRESH_ACTIVITIES',
  DELETE_MEETING = 'CLINICAL_MEETINGS_DELETE_MEETING',
  PATCH_MEETING = 'CLINICAL_MEETINGS_PATCH_MEETING',
  CHANGE_VIEW = 'CLINICAL_MEETINGS_CHANGE_VIEW',
  EXPORT_TO_EXCEL = 'CLINICAL_MEETINGS_EXPORT_TO_EXCEL',
}
