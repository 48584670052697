import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from 'store/store';
import * as dynamic from 'utils/dynamic-helpers';
import { selectSideEffectsFilters } from './selectors';
import { createFilterDateISO, createFilterValueArrayEquals } from 'utils/dynamic-helpers';
import { WithSideEffectItem } from '../models';
import { ServiceUserPatientProfileSessions } from 'services/user-patient-profile-session';

export const actionSideEffectsLoadData = createAsyncThunk<
  WithSideEffectItem[],
  void,
  AppAsyncThunkConfig
>(`SIDE_EFFECTS/loadData`, async (_, { getState }) => {
  try {
    const { age, dates, sideEffectIDs } = selectSideEffectsFilters(getState());

    const {
      data: { value },
    } = await ServiceUserPatientProfileSessions.getAllDynamic<WithSideEffectItem>({
      select: dynamic.select(
        'userPatientProfileID',
        'entryDate',
        `new { userEmployeeProfile.appIdentityUserID } as employee`,
        `new {
            userPatientProfile.fullName,
            userPatientProfile.shortRemark,
            userPatientProfile.idNumber,
            userPatientProfile.mobilePhone,
            userPatientProfile.dateOfBirth,
            userPatientProfile.isActive,
            userPatientProfile.isTLC,
            userPatientProfile.onHold,
            userPatientProfile.onHoldReason,
            userPatientProfile.doNotProlongTreatment,
            userPatientProfile.slowIncreaseWeight,
            userPatientProfile.changeDosageByDoctorApproval,
            userPatientProfile.userPatientProfileSubscriptions
                .OrderByDescending(endDate).Where(s => s.isActive)
                .Select(s => new {
                    s.id,
                    s.startDate,
                    s.endDate
            }).FirstOrDefault() as subscription
          } as patient`,
        'userPatientProfileSessionSideEffects.Select(s=> new { s.icd10.id, s.icd10.code, s.icd10.titleHeb, s.icd10.description }) as sideEffects',
      ),
      filter: dynamic
        .mergeFilters(
          'userPatientProfileSessionSideEffects.Count > 0',
          sideEffectIDs.length
            ? `userPatientProfileSessionSideEffects.Any(s => ${createFilterValueArrayEquals(
                's.icd10ID',
                sideEffectIDs,
              )})`
            : null,

          createFilterDateISO('entryDate', dates),
          `(DateTime.Today.Year - DateTime(userPatientProfile.dateOfBirth).Year) >= ${age[0]}`,
          `(DateTime.Today.Year - DateTime(userPatientProfile.dateOfBirth).Year) <= ${age[1]}`,
        )
        .join('&&'),
    });

    return value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
