import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { compareDesc, isWithinInterval } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { formatWeight } from 'utils/numbers';
const selectLoosingWeightsState = (state: RootState) => state.loosingWeight;
export const selectLoosingWeightsStatuses = createSelector(
  selectLoosingWeightsState,
  ({ isLoading }) => {
    return { isLoading };
  },
);
export const selectLoosingWeightsFilters = createSelector(
  selectLoosingWeightsState,
  ({ filters }) => {
    return filters;
  },
);
const selectLoosingWeights = createSelector(selectLoosingWeightsState, ({ weights }) => {
  return weights;
});
const selectLoosingWeightsFilterTypeID = createSelector(
  selectLoosingWeightsFilters,
  ({ typeID }) => {
    return typeID;
  },
);
const selectLoosingWeightsFilterForDates = createSelector(
  selectLoosingWeightsFilters,
  ({ startDate, endDate }) => {
    return { startDate, endDate };
  },
);
const selectLoosingWeightsFilteredByType = createSelector(
  selectLoosingWeights,
  selectLoosingWeightsFilterTypeID,
  (weights, typeID) => {
    if (!typeID) return weights;

    return weights.filter((weight) => {
      return weight.typeID === typeID;
    });
  },
);
const selectLoosingWeightsLastWeightMap = createSelector(
  selectLoosingWeightsFilteredByType,
  (weights) => {
    const map = new Map<string, number>();
    weights.forEach((item) => {
      const key = item.userPatientProfileID;
      if (!map.has(key)) {
        map.set(key, item.weight);
      }
    });

    return Array.from(map.entries()).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, number>);
  },
);

const selectLoosingWeightsFilteredByDate = createSelector(
  selectLoosingWeightsFilteredByType,
  selectLoosingWeightsFilterForDates,
  (weights, { startDate, endDate }) => {
    return weights.filter(({ entryDate }) => {
      return isWithinInterval(convertToDate(entryDate), {
        start: convertToDate(startDate),
        end: convertToDate(endDate),
      });
    });
  },
);

const selectLoosingWeightsData = createSelector(
  selectLoosingWeightsFilteredByDate,
  selectLoosingWeightsLastWeightMap,
  (data, mapLastWeight) => {
    let patientsMap = new Map<string, typeof data>();

    data.forEach((item) => {
      const existing = patientsMap.get(item.userPatientProfileID) || [];
      existing.push(item);
      patientsMap.set(item.userPatientProfileID, existing);
    });

    return Array.from(patientsMap.entries()).map((entry) => {
      const firstRecord = entry[1][0];
      const weights = Array.from(entry[1]).sort((a, b) =>
        compareDesc(convertToDate(a.entryDate), convertToDate(b.entryDate)),
      );

      const firstWeightEntity = weights[0];
      const lastWeightEntity = weights[weights.length - 1];

      const delta = formatWeight(firstWeightEntity.weight - lastWeightEntity.weight);

      const appIdentityUserID = entry[0];
      return {
        appIdentityUserID,
        fullName: String(firstRecord.patient.fullName),
        shortRemark: String(firstRecord.patient.shortRemark),
        idNumber: String(firstRecord.patient.idNumber),
        mobilePhone: String(firstRecord.patient.mobilePhone),
        dateOfBirth: String(firstRecord.patient.dateOfBirth),
        subscription: firstRecord.patient.subscription,
        weight: firstRecord.patient.weight || 0,
        isTLC: !!firstRecord.patient.isTLC,
        onHold: !!firstRecord.patient.onHold,
        onHoldReason: firstRecord.patient.onHoldReason ?? null,
        doNotProlongTreatment: !!firstRecord.patient.doNotProlongTreatment,
        slowIncreaseWeight: !!firstRecord.patient.slowIncreaseWeight,
        changeDosageByDoctorApproval: !!firstRecord.patient.changeDosageByDoctorApproval,
        maintenanceWeight: firstRecord.patient.maintenanceWeight || 0,
        lastWeight: mapLastWeight[appIdentityUserID] || 0,
        delta,
        isActive: !!firstRecord.patient.isActive,
        weightsCount: weights.length,
      };
    });
  },
);

export const selectLoosingWeightsDataFiltered = createSelector(
  selectLoosingWeightsData,
  selectLoosingWeightsFilters,
  (data, { deltaStart, deltaEnd }) => {
    return data.filter((row) => {
      const res = [true];

      // filter by delta
      const min = Math.min(deltaStart, deltaEnd);
      const max = Math.max(deltaStart, deltaEnd);
      const value = Number(row.delta);
      res.push(value >= parseFloat(String(min)) && value <= parseFloat(String(max)));

      // remove the patients with a single weight in the range;
      res.push(row.weightsCount > 1);

      // remove the patients that the delta between the maintenance and last weight is an abs of 2 kg
      res.push(Math.abs(row.maintenanceWeight - row.lastWeight) > 2);

      return res.every(Boolean);
    });
  },
);

export const selectLoosingDeltaExtremum = createSelector(
  selectLoosingWeightsDataFiltered,
  (data) => {
    let list = data.map((row) => parseFloat(row.delta));
    list = list.length === 0 ? [0] : list;

    return { max: Math.max(...list), min: Math.min(...list) };
  },
);
