import React, { useMemo } from 'react';
import { GridModel } from '../../models';
import { DialogImagesPreview } from 'components/dialog-images-preview';
import { useOpen } from 'AurionCR/components/hooks';
import { Button, CircularProgress } from '@material-ui/core';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { useTranslate } from 'hooks/use-translate';
import { apiMediaPrivate } from 'services/media-private-services';

const useFetchImagesQuery = apiMediaPrivate.useGetMediaPrivateAsBase64ListQuery;

interface Props {
  row: GridModel;
}
export const ColumnImages: React.FC<Props> = ({ row }) => {
  const { tp } = useTranslate();
  const { isOpen, handleToggle, handleClose } = useOpen();
  const isDisabled = row.fileURLs.length === 0;
  const { data: fileURLs, isFetching } = useFetchImagesQuery(row.fileURLs, {
    skip: isDisabled || !isOpen,
  });

  const images = useMemo(() => {
    return (fileURLs || []).map((base64, index) => {
      return { picture: base64, title: String(index + 1) };
    });
  }, [fileURLs]);

  const isRenderDialog = isOpen && images.length > 0;
  return (
    <>
      <Button
        onClick={handleToggle}
        size={'small'}
        variant={'outlined'}
        color={'primary'}
        style={{ whiteSpace: 'nowrap' }}
        disabled={isDisabled}
        startIcon={isFetching ? <CircularProgress size={16} /> : <PhotoLibraryIcon />}
      >
        {tp('view-all-photos')}
      </Button>
      {isRenderDialog && <DialogImagesPreview images={images} onClose={handleClose} />}
    </>
  );
};
