import React, { useCallback } from 'react';
import style from './index.module.scss';
import { useValueQuery } from 'hooks/use-value-query';
import clsx from 'clsx';
import { TemplatePatientFullName } from '../template-patient-full-name';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import { DateValue } from 'utils/dates';
import { Unset } from 'utils/types';

interface Props extends ButtonBaseProps {
  userPatientProfileID: string | null | undefined;
  fullName: string | null | undefined;
  isActive: boolean | undefined;
  onHold: boolean | undefined;
  onHoldReason: Unset;
  isTLC: boolean | undefined;
  slowIncreaseWeight: boolean | undefined;
  doNotProlongTreatment: boolean | undefined;
  changeDosageByDoctorApproval: boolean | undefined;
  dateOfBirth: DateValue;
}

export const TEMPLATE_PATIENT_CARD_QUERY_NAME = 'patient';

export const TemplatePatientCard: React.FC<Props> = ({
  fullName,
  userPatientProfileID,
  isActive,
  isTLC,
  onHold,
  onHoldReason,
  doNotProlongTreatment,
  slowIncreaseWeight,
  dateOfBirth,
  changeDosageByDoctorApproval,
  ...rest
}) => {
  const { onChange } = useValueQuery<string>({ name: TEMPLATE_PATIENT_CARD_QUERY_NAME });

  const onClick = useCallback(() => {
    onChange(String(userPatientProfileID));
  }, [onChange, userPatientProfileID]);
  const disabled = !userPatientProfileID;
  return (
    <ButtonBase
      className={clsx({ [style.root]: true, [style.clickable]: !disabled })}
      onClick={onClick}
      disabled={disabled}
      disableRipple={!onClick}
      {...rest}
    >
      <div>
        <TemplatePatientFullName
          isTLC={isTLC}
          isActive={isActive}
          onHold={onHold}
          onHoldReason={onHoldReason}
          className={clsx(style.fullName)}
          slowIncreaseWeight={slowIncreaseWeight}
          doNotProlongTreatment={doNotProlongTreatment}
          changeDosageByDoctorApproval={changeDosageByDoctorApproval}
          dateOfBirth={dateOfBirth}
        >
          {fullName}
        </TemplatePatientFullName>
      </div>
      {!!onClick && <ReplyIcon className={style.icon} />}
    </ButtonBase>
  );
};
