import {
  iTaskManager,
  iTMActionTypes,
  iTMCalendarSupportMeeting,
  iTMEmployee,
  iTMListSupportMeeting,
  iTMMeetingType,
  iTMSupportMeeting,
  iTMSupportMeetingCanToMove,
} from './@type';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import {
  actionTMSendMessageBulk,
  actionTMSetClinicalMeetings,
  TMChangeView,
  TMDeleteMeeting,
  TMFilterMerge,
  TMItemData,
  TMMerge,
  TMMoveAll,
  TMMoveItem,
  TMNotebookPath,
  TMRefresh,
  TMViewMerge,
} from './helpers';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { apiStatic, apiStaticCanceled, requestError } from 'AurionCR/components';
import {
  createFilterContains,
  createFilterDateLessOrEqualsISO,
  createFilterDateMoreISO,
  createFilterEquals,
  createFilterEqualsSome,
  createFilterSmartSearch,
  mergeFilters,
  orderBy,
} from 'utils/dynamic-helpers';
import { API_USER_EMPLOYEE_PROFILES } from 'services/user-employee-profiles';
import {
  API_SUPPORT_MEETINGS,
  apiSupportMeeting,
  ServiceSupportMeetings,
  SupportMeeting,
  SupportMeetingExcel,
} from 'services/support-meetings';
import { API_SUPPORT_MEETING_TYPES } from 'services/support-meeting-types';
import { differenceInMinutes, endOfDay, format, isSameDay, set, startOfDay } from 'date-fns';
import axios from 'axios';
import { keyBy, pick } from 'lodash-es';
import { SupportMeetingActivity } from 'services/support-meeting-activities';
import { getRandomString } from 'utils/other';
import { calcPaginationSkip } from 'utils/service';
import { PERMISSION_IDS } from 'services/user-employee-profile-permissions';
import { selectTMData, selectTMListData } from './selectors';
import { APP_FORMAT_DATE_TIME, CALENDAR_RANGE_MINUTES } from 'configs/const';
import { ServiceUserPatientProfile, UserPatientProfile } from 'services/user-patient-profile';
import { calcHtmlTemplate } from 'utils';

import { actionSupportMeetingActivitiesCrateLogSendSms } from 'store/support-meeting-activities';
import { makeFilterPatientsActive } from '../../helpers';
import { i18nAppTranslator } from 'modules/i18n';
import { convertToDate, dateFormat, DateValue } from 'utils/dates';
import { ModuleExportExcel } from 'modules/export-excel';
import { ServiceClinicalMeetings } from 'services/clinical-meetings';

function* loadClinicalMeetings(dates: DateValue[]) {
  const dateRange = [
    dates[0] && startOfDay(convertToDate(dates[0])),
    dates[1] && endOfDay(convertToDate(dates[1])),
  ].filter(Boolean);

  if (dateRange.length < 2) {
    return yield* put(actionTMSetClinicalMeetings([]));
  }
  if (!isSameDay(convertToDate(dateRange[0]), convertToDate(dateRange[1]))) {
    return yield* put(actionTMSetClinicalMeetings([]));
  }
  try {
    const {
      data: { value },
    } = yield* call(
      ServiceClinicalMeetings.getActiveMeetingsWithDoctorOrDietitianByRange,
      dateRange,
    );
    return yield* put(actionTMSetClinicalMeetings(value));
  } catch (e) {
    return yield* put(actionTMSetClinicalMeetings([]));
  }
}

const GetCanToMovedMeetingsID = (values: iTMSupportMeeting[]) =>
  values.reduce((acc: iTMSupportMeetingCanToMove[], item) => {
    if (!item.userPatientProfileSessionID)
      acc.push(
        pick(item, ['id', 'meetingFromDateTime', 'meetingToDateTime', 'userPatientProfileID']),
      );
    return acc;
  }, []);

function* handleError(e: any) {
  yield put(notifyRequestResult(requestError(e), 'error'));
}

function* handleSuccess() {
  yield put(notifyRequestResult());
}

function* loadEmployees() {
  try {
    const {
      data: { value },
    }: {
      data: { value: iTMEmployee[] };
    } = yield call(apiStatic.get, API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC, {
      params: {
        select: [
          'appIdentityUserID',
          'userPhoto',
          'fullName',
          'userEmployeeProfilePermissionID',
          'userCrmProfilePermission.title as department',
          'userCrmProfilePermission.color as color',
          'userEmployeeProfileWorkLogs.Where(k => k.isActive == true).Select(k => new { k.id, k.fromTime, k.toTime, k.dayOfWeek }) as workLogs',
        ].join(),
        filter: mergeFilters(
          `userEmployeeProfilePermissionID=="${PERMISSION_IDS.MANAGER}"`,
          `userEmployeeProfilePermissionID=="${PERMISSION_IDS.PATIENT_MANAGER_AND_TELEMARKETING}"`,
        ).join('||'),
        orderBy: 'firstName,lastName',
      },
    });

    yield put(
      TMMerge({
        employees: value.reduce(
          (acc: iTaskManager['employees'], item) => {
            acc.list.push((acc.map[item.appIdentityUserID] = item));
            return acc;
          },
          { list: [], map: {} },
        ),
      }),
    );
  } catch (e) {
    yield call(handleError, e);
  }
}

function* loadMeetingTypes() {
  try {
    const {
      data: { value },
    }: {
      data: { value: iTMMeetingType[] };
    } = yield call(apiStatic.get, API_SUPPORT_MEETING_TYPES.GET_ALL_DYNAMIC, {
      params: {
        select: [
          'id',
          'title',
          'color',
          'icon',
          'isPrescriptionRenewal',
          'isActive',
          'isCanceledMeeting',
        ].join(),
        orderBy: 'rank',
      },
    });

    yield* put(
      TMMerge({
        meetingTypes: value.reduce(
          (acc: iTaskManager['meetingTypes'], item) => {
            acc.list.push((acc.map[item.id] = item));
            return acc;
          },
          { list: [], map: {} },
        ),
      }),
    );
  } catch (e) {
    yield call(handleError, e);
  }
}

function* loadCalendarData() {
  const {
    calendar: {
      filters: { date, supportMeetingTypeIDs, taskType, search, isActivePatient },
    },
  }: iTaskManager = yield select((state) => state.TaskManager);
  yield put(TMViewMerge({ view: 'calendar', loading: true }));
  try {
    const {
      data: { value },
    }: {
      data: { value: iTMCalendarSupportMeeting[] };
    } = yield call(apiStaticCanceled, {
      url: API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC,
      _cancelID: `${API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC}-CALENDAR`,
      params: {
        select: [
          'id',
          'isActive',
          'userEmployeeProfileID',
          'userEmployeeProfile.fullName as userEmployeeProfileFullName',
          'supportMeetingTypeID',
          'supportMeetingType.isPrescriptionRenewal as isPrescriptionRenewal',
          'userPatientProfileSessionID',
          'userPatientProfileSession.notebook.labelKey as userPatientProfileSessionNotebookLabelKey',
          'userPatientProfileID',
          'new { userPatientProfile.mobilePhone, userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.dateOfBirth, userPatientProfile.isActive, userPatientProfile.isTLC, userPatientProfile.onHold, userPatientProfile.onHoldReason, userPatientProfile.doNotProlongTreatment, userPatientProfile.slowIncreaseWeight, userPatientProfile.changeDosageByDoctorApproval } as userPatientProfile',
          'meetingFromDateTime',
          'meetingToDateTime',
        ].join(),
        filter: [
          'userEmployeeProfileID!=null',
          'meetingFromDateTime!=null',
          'meetingToDateTime!=null',
          createFilterDateMoreISO('meetingFromDateTime', startOfDay(date)),
          createFilterDateLessOrEqualsISO('meetingToDateTime', endOfDay(date)),
          createFilterEqualsSome('supportMeetingTypeID', supportMeetingTypeIDs),
          taskType ? taskType : undefined,
          search
            ? mergeFilters(
                createFilterContains<SupportMeeting>(
                  ['userPatientProfileSession.notebook.labelKey'],
                  search,
                ),
                createFilterSmartSearch<SupportMeeting>(
                  ['userPatientProfile.firstName', 'userPatientProfile.lastName'],
                  search,
                ),
                createFilterSmartSearch<SupportMeeting>(
                  ['userEmployeeProfile.firstName', 'userEmployeeProfile.lastName'],
                  search,
                ),
              ).join('||')
            : undefined,
          makeFilterPatientsActive(isActivePatient),
        ]
          .filter((item) => item)
          .join('&&'),
      },
    });
    // parser
    // const employeesActiveMap: { [id: string]: string } = {};
    const { dayViewItems, dayViewItemsPrescription } = value.reduce(
      (
        acc: {
          dayViewItems: iTaskManager['calendar']['dayViewItems'];
          dayViewItemsPrescription: iTaskManager['calendar']['dayViewItemsPrescription'];
        },
        item,
      ) => {
        const { userEmployeeProfileID } = item;
        if (item.isPrescriptionRenewal) {
          // PRESCRIPTIONS
          if (!acc.dayViewItemsPrescription[userEmployeeProfileID]) {
            acc.dayViewItemsPrescription[userEmployeeProfileID] = [];
          }
          acc.dayViewItemsPrescription[userEmployeeProfileID].push(item);
          // // add employee to axios
          // employeesActiveMap[item.userEmployeeProfileID] = item.userEmployeeProfileID;
        } else {
          // GRID
          const columnID = `${format(
            new Date(item.meetingFromDateTime),
            'HH:mm',
          )}_${userEmployeeProfileID}`;
          if (!acc.dayViewItems[columnID]) acc.dayViewItems[columnID] = [];
          acc.dayViewItems[columnID].push({
            id: item.id,
            rows: Math.trunc(
              differenceInMinutes(
                new Date(item.meetingToDateTime),
                new Date(item.meetingFromDateTime),
              ) / CALENDAR_RANGE_MINUTES,
            ),
            maxRows: 1,
            data: item,
          });
          // // add employee to axios
          // employeesActiveMap[item.userEmployeeProfileID] = item.userEmployeeProfileID;
        }
        return acc;
      },
      { dayViewItems: {}, dayViewItemsPrescription: {} },
    );

    yield* call(loadClinicalMeetings, [date, date]);

    // set data
    yield put(
      TMViewMerge({
        view: 'calendar',
        loading: false,
        data: value,
        dayViewItems,
        dayViewItemsPrescription,
        canToMove: GetCanToMovedMeetingsID(value),
      }),
    );
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield call(handleError, e);
      yield put(TMViewMerge({ view: 'calendar', loading: true }));
    }
  }
}
function* loadItemData({ payload: { id } }: ReturnType<typeof TMItemData>) {
  yield put(TMViewMerge({ view: 'list', loading: true }));
  try {
    let params = {
      select: [
        'id',
        'supportMeetingTypeID',

        'meetingFromDateTime',
        'meetingToDateTime',

        'userPatientProfileID',
        'new { userPatientProfile.mobilePhone, userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.dateOfBirth, userPatientProfile.isActive, userPatientProfile.isTLC, userPatientProfile.onHold, userPatientProfile.onHoldReason, userPatientProfile.doNotProlongTreatment, userPatientProfile.slowIncreaseWeight, userPatientProfile.changeDosageByDoctorApproval } as userPatientProfile',

        'userEmployeeProfileID',
        'userEmployeeProfile.fullName as userEmployeeProfileFullName',

        'supportMeetingType.isPrescriptionRenewal as isPrescriptionRenewal',
        'userPatientProfileSessionID',
        'userPatientProfileSession.notebook.labelKey as userPatientProfileSessionNotebookLabelKey',

        'supportMeetingActivities.Count() as supportMeetingActivitiesCount',

        'remarks',

        'supportMeetingActivities.OrderByDescending(a=>a.entryDate).Select(a=>a.remarkForPatientCallStatus.title).FirstOrDefault() as lastActivityType',
        'userPatientProfile.userPatientProfileSubscriptions.OrderByDescending(z => z.endDate).Take(1).Select(k => new {k.endDate}) as subscriptions',
      ].join(),
      filter: [`id=="${id}"`].filter(Boolean).join('&&'),
      count: true,
      take: 1,
    };
    // request
    const {
      data: { value },
    }: {
      data: { value: iTMListSupportMeeting[]; count: number };
    } = yield call(apiStaticCanceled, {
      url: API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC,
      _cancelID: `${API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC}-CALENDAR`,
      params,
    });
    // set data
    yield put(
      TMViewMerge({
        view: 'list',
        loading: false,
      }),
    );
    yield put(
      TMMerge({
        itemData: value?.[0],
      }),
    );
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield call(handleError, e);
      yield put(TMViewMerge({ view: 'list', loading: false }));
    }
  }
}
function* makeListFilters() {
  const {
    list: {
      filters: {
        date,
        supportMeetingTypeIDs,
        userEmployeeProfileID,
        remarkForPatientCallStatusID,
        taskType,
        search,
        isActivePatient,
      },
    },
  }: iTaskManager = yield select((state) => state.TaskManager);

  return mergeFilters(
    'userEmployeeProfileID!=null',
    'meetingFromDateTime!=null',
    'meetingToDateTime!=null',
    remarkForPatientCallStatusID &&
      `supportMeetingActivities.OrderByDescending(a=>a.entryDate).Select(a=>a.remarkForPatientCallStatusID).FirstOrDefault()=="${remarkForPatientCallStatusID}"`,
    date.length === 2 &&
      mergeFilters(
        createFilterDateMoreISO('meetingFromDateTime', startOfDay(date[0])),
        createFilterDateLessOrEqualsISO('meetingToDateTime', endOfDay(date[1])),
      ).join('&&'),
    createFilterEqualsSome('supportMeetingTypeID', supportMeetingTypeIDs),
    createFilterEquals('userEmployeeProfileID', userEmployeeProfileID),
    taskType ? taskType : undefined,
    search
      ? mergeFilters(
          createFilterContains<SupportMeeting>(
            ['userPatientProfileSession.notebook.labelKey', 'remarks'],
            search,
          ),
          createFilterSmartSearch<SupportMeeting>(
            ['userPatientProfile.firstName', 'userPatientProfile.lastName'],
            search,
          ),
          createFilterSmartSearch<SupportMeeting>(
            ['userEmployeeProfile.firstName', 'userEmployeeProfile.lastName'],
            search,
          ),
        ).join('||')
      : undefined,
    makeFilterPatientsActive(isActivePatient),
  ).join('&&');
}
function* makeListOrder() {
  const {
    list: { orderField, orderDirection },
  }: iTaskManager = yield select((state) => state.TaskManager);
  if (!orderField) {
    return 'meetingFromDateTime,meetingToDateTime';
  }
  return orderBy(orderField, orderDirection);
}
function* fetchListData() {
  const {
    list: {
      page,
      pageSize,
      filters: { date },
    },
  }: iTaskManager = yield select((state) => state.TaskManager);

  const filters = yield* makeListFilters();
  const orderBy = yield* makeListOrder();
  const skip = calcPaginationSkip({ page, take: pageSize });

  let params = {
    select: [
      'id',
      'supportMeetingTypeID',
      'isActive',

      'meetingFromDateTime',
      'meetingToDateTime',

      'userPatientProfileID',
      'new { userPatientProfile.mobilePhone, userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.dateOfBirth, userPatientProfile.isActive, userPatientProfile.shortRemark, userPatientProfile.isTLC, userPatientProfile.onHold, userPatientProfile.onHoldReason } as userPatientProfile',

      'userEmployeeProfileID',
      'userEmployeeProfile.fullName as userEmployeeProfileFullName',

      'supportMeetingType.isPrescriptionRenewal as isPrescriptionRenewal',
      'userPatientProfileSessionID',
      'userPatientProfileSession.notebook.labelKey as userPatientProfileSessionNotebookLabelKey',

      'supportMeetingActivities.Count() as supportMeetingActivitiesCount',

      'remarks',
      'supportMeetingActivities.OrderByDescending(a=>a.entryDate).Select(a=>a.remarkForPatientCallStatus.title).FirstOrDefault() as lastActivityType',
      'userPatientProfile.userPatientProfileSubscriptions.OrderByDescending(z => z.endDate).Take(1).Select(k => new {k.endDate}) as subscriptions',
    ].join(),
    filter: filters,
    count: true,
    take: pageSize,
    skip: skip,
    orderBy,
  };

  // request
  const {
    data: { value, count },
  }: {
    data: { value: iTMListSupportMeeting[]; count: number };
  } = yield call(apiStaticCanceled, {
    url: API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC,
    _cancelID: `${API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC}-CALENDAR`,
    params,
  });

  yield* call(loadClinicalMeetings, date);

  return { value, count };
}
function* loadListData() {
  yield* put(TMViewMerge({ view: 'list', loading: true }));
  try {
    const { value, count } = yield* fetchListData();
    // set data
    yield put(
      TMViewMerge({
        view: 'list',
        loading: false,
        data: value,
        dataCount: count,
        canToMove: GetCanToMovedMeetingsID(value),
        triggerScrollUpdate: getRandomString(),
      }),
    );
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield call(handleError, e);
      yield put(TMViewMerge({ view: 'list', loading: false }));
    }
  }
}
function* refresh({ payload }: ReturnType<typeof TMRefresh>) {
  const {
    init,
    calendar: { loading: loadingCalendar },
    list: { loading: loadingList },
    view,
  }: iTaskManager = yield select((state) => state.TaskManager);

  if (init) {
    if (view === 'calendar') {
      if (!loadingCalendar || payload) {
        yield put(TMViewMerge({ view: 'calendar', dialogDayViewItems: null }));
        yield call(loadCalendarData);
      }
    } else if (view === 'list') {
      if (!loadingList || payload) {
        yield call(loadListData);
      }
    }
  }
}
function* refreshActivities() {
  try {
    const { value } = yield* fetchListData();

    const mapValue = keyBy(value, 'id');

    const currentList = yield* select(selectTMListData);
    const newValue = currentList.map((item) => {
      const newItem = mapValue[item.id];
      if (!newItem) {
        return item;
      }
      return { ...item, ...newItem };
    });

    // set data
    yield put(
      TMViewMerge({
        view: 'list',
        isRefreshingActivities: false,
        data: newValue,
        canToMove: GetCanToMovedMeetingsID(newValue),
      }),
    );
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield call(handleError, e);
      yield put(TMViewMerge({ view: 'list', isRefreshingActivities: false }));
    }
  }
}
function* init() {
  const {
    TaskManager: { init, loading },
    auth: {
      user: { appUserID },
    },
  } = yield select((state) => state);
  if (!init && !loading) {
    yield put(TMMerge({ loading: true }));
    yield all([call(loadEmployees), call(loadMeetingTypes)]);
    // set defaults filters
    yield put(TMFilterMerge({ view: 'list', userEmployeeProfileID: appUserID }));
    yield put(TMMerge({ loading: false, init: true }));
  }
  yield put(TMRefresh());
}
function* deleteMeeting({ payload: { id } }: ReturnType<typeof TMDeleteMeeting>) {
  if (!id) return;
  yield put(
    TMMerge({
      loading: true,
      confirmDeleteMeeting: null,
      dialogPrescription: null,
      editMeeting: null,
    }),
  );
  try {
    yield call(ServiceSupportMeetings.delete, { id });
    yield call(handleSuccess);
    yield put(TMRefresh(true));
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(TMMerge({ loading: false }));
}
function* notebookPath({ payload }: ReturnType<typeof TMNotebookPath>) {
  yield put(TMMerge({ loading: true, dialogNotebookFormGenerator: null, editMeeting: null }));
  try {
    yield call(apiStatic.patch, API_SUPPORT_MEETINGS.PATCH(payload), payload);
    yield call(handleSuccess);
    yield put(TMRefresh(true));
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(TMMerge({ loading: false }));
}
function* changeView({ payload: view }: ReturnType<typeof TMChangeView>) {
  yield put(TMMerge({ view }));
  yield put(TMRefresh());
}
function* moveAll({ payload: { date, ...rest } }: ReturnType<typeof TMMoveAll>) {
  yield put(TMMerge({ loading: true }));
  const {
    TaskManager: {
      view,
      calendar: { canToMove: calendarCanMove },
      list: { canToMove: listCanMove },
    },
  } = yield select((state) => state);

  const items: iTMSupportMeetingCanToMove[] = view === 'calendar' ? calendarCanMove : listCanMove;
  try {
    yield all(items.map((item) => call(moveMeeting, { meeting: item, date, activity: rest })));
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(TMMerge({ loading: false }));
  yield put(TMRefresh());
}

function* moveMeeting({
  date,
  meeting,
  activity,
}: {
  date: DateValue;
  meeting: Pick<
    iTMCalendarSupportMeeting,
    'meetingFromDateTime' | 'meetingToDateTime' | 'id' | 'userPatientProfileID'
  >;
  activity: Pick<Partial<SupportMeetingActivity>, 'remarks' | 'remarkForPatientCallStatusID'>;
}) {
  const fromDate = convertToDate(meeting.meetingFromDateTime);
  const toDate = convertToDate(meeting.meetingToDateTime);

  const meetingFromDateTime = set(convertToDate(date), {
    hours: fromDate.getHours(),
    minutes: fromDate.getMinutes(),
    seconds: fromDate.getSeconds(),
    milliseconds: fromDate.getMilliseconds(),
  });
  const meetingToDateTime = set(convertToDate(date), {
    hours: toDate.getHours(),
    minutes: toDate.getMinutes(),
    seconds: toDate.getSeconds(),
    milliseconds: toDate.getMilliseconds(),
  });

  const res = yield* put(
    apiSupportMeeting.endpoints.patchSupportMeetingWithLog.initiate({
      initData: {
        id: meeting.id,
        userPatientProfileID: meeting.userPatientProfileID,
        meetingFromDateTime: fromDate.toISOString(),
        meetingToDateTime: toDate.toISOString(),
      },
      formData: {
        id: meeting.id,
        userPatientProfileID: meeting.userPatientProfileID,
        meetingFromDateTime: meetingFromDateTime.toISOString(),
        meetingToDateTime: meetingToDateTime.toISOString(),
      },
      remark: activity.remarks,
      remarkForPatientCallStatusID: activity.remarkForPatientCallStatusID,
    }),
  );
  yield* call(res.unwrap);
}
function* moveItem({ payload }: ReturnType<typeof TMMoveItem>) {
  yield put(TMMerge({ loading: true }));
  const { date, data, ...rest } = payload;
  try {
    yield* call(moveMeeting, { date: date, meeting: data, activity: rest });
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(TMMerge({ loading: false }));
  yield put(TMRefresh());
}

type SendSmsToPatientInput = {
  appIdentityUserID: string;
  user: Pick<UserPatientProfile, 'firstName' | 'lastName' | 'mobilePhone'>;
  meetings: { id: string }[];
  message: string;
};
function* performSendSmsToPatient(input: SendSmsToPatientInput) {
  const message = calcHtmlTemplate(input.message, {
    patient: input.user,
  });

  yield* call(ServiceUserPatientProfile.sendSms, {
    userPatientProfileID: input.appIdentityUserID,
    message,
    toPhoneNumber: String(input.user.mobilePhone),
  });

  yield* all(
    input.meetings.map(function* ({ id }) {
      yield* put(
        actionSupportMeetingActivitiesCrateLogSendSms({
          supportMeetingID: id,
          message,
        }),
      );
    }),
  );
}
function* sagaSendMessageBulk(action: ReturnType<typeof actionTMSendMessageBulk>) {
  const { message } = action.payload;

  const meetings = yield* select(selectTMData);

  const mapUsers = meetings.reduce((acc, meeting) => {
    const key = meeting.userPatientProfileID;
    let item = acc[key];

    if (item) {
      acc[key] = { ...acc[key], meetings: [...acc[key].meetings, meeting] };
    } else {
      acc[key] = { user: meeting.userPatientProfile, meetings: [meeting] };
    }
    return acc;
  }, {} as Record<string, Pick<SendSmsToPatientInput, 'user' | 'meetings'>>);

  const filteredEntries = Object.entries(mapUsers).filter(([key, data]) => {
    return data.user.mobilePhone;
  });

  yield* all(
    filteredEntries.map(function ([key, data]) {
      return call(performSendSmsToPatient, {
        appIdentityUserID: key,
        user: data.user,
        meetings: data.meetings,
        message,
      });
    }),
  );

  yield* call(handleSuccess);
}

function* sagaExportToExcel() {
  const filters = yield* makeListFilters();
  const orderBy = yield* makeListOrder();
  yield* put(TMMerge({ loading: true }));
  try {
    const {
      data: { value },
    } = yield* call(ServiceSupportMeetings.getExcelData, {
      filter: filters,
      orderBy,
    });

    const title = [i18nAppTranslator.t('task-manager'), dateFormat(new Date())].join('__');

    const { wb_out } = yield* call(ModuleExportExcel.export, {
      columns: [
        {
          title: i18nAppTranslator.t('support-meeting-type'),
          field: '',
          valueTemplate: (data: SupportMeetingExcel) => data.supportMeetingType.title || '',
        },
        {
          title: i18nAppTranslator.t('meeting-from-date-time'),
          field: 'meetingFromDateTime',
          type: 'date' as const,
          format: APP_FORMAT_DATE_TIME,
        },
        {
          title: i18nAppTranslator.t('meeting-to-date-time'),
          field: 'meetingToDateTime',
          type: 'date' as const,
          format: APP_FORMAT_DATE_TIME,
          valueTemplate: (data: SupportMeetingExcel) =>
            set(convertToDate(data.meetingToDateTime), {
              seconds: 0,
              milliseconds: 0,
            }),
        },
        {
          title: i18nAppTranslator.t('patient'),
          field: '',
          valueTemplate: (data: SupportMeetingExcel) => data.userPatientProfile.fullName || '',
        },
        {
          title: i18nAppTranslator.t('employee'),
          field: '',
          valueTemplate: (data: SupportMeetingExcel) => data.userEmployeeProfile.fullName || '',
        },
        {
          title: i18nAppTranslator.t('activities'),
          field: 'activities',
        },
        {
          title: i18nAppTranslator.t('last-activity-type'),
          field: 'lastActivityType',
        },
        {
          title: i18nAppTranslator.t('remarks'),
          field: 'remarks',
        },
        {
          title: i18nAppTranslator.t('program-info-end-date'),
          field: '',
          valueTemplate: (data: SupportMeetingExcel) => data.subscription?.endDate || '',
          type: 'date' as const,
        },
      ],
      data: value,
      settings: {
        title,
      },
    });

    yield* call(ModuleExportExcel.save, { wb_out, name: title });
  } catch (e) {
    yield* call(handleError, e);
  } finally {
    yield* put(TMMerge({ loading: false }));
  }
}

export const TaskManagerSagas = [
  takeLatest(iTMActionTypes.TM_INIT, init),
  takeLatest(iTMActionTypes.TM_REFRESH, refresh),
  takeLatest(iTMActionTypes.TM_REFRESH_ACTIVITIES, refreshActivities),
  takeLatest(iTMActionTypes.TM_DELETE_MEETING, deleteMeeting),
  takeLatest(iTMActionTypes.TM_NOTEBOOK_PATH, notebookPath),
  takeLatest(iTMActionTypes.TM_CHANGE_VIEW, changeView),
  takeLatest(iTMActionTypes.TM_MOVE_ALL, moveAll),
  takeLatest(iTMActionTypes.TM_MOVE_ITEM, moveItem),
  takeLatest(iTMActionTypes.TM_LOAD_ITEM_DATA, loadItemData),
  takeLatest(iTMActionTypes.TM_SEND_MESSAGES_BULK, sagaSendMessageBulk),
  takeLatest(iTMActionTypes.EXPORT_TO_EXCEL, sagaExportToExcel),
];
