import {
  CLINICAL_MEETING_ACTION_TYPES,
  EmployeeTimeSlot,
  IClinicalMeeting,
  IClinicalMeetingListItem,
  ICMEmployee,
  ICMMeetingSubject,
  ICMMeetingType,
} from './@type';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import {
  actionCMCalendarSetTimeslots,
  actionCMChangeView,
  actionCMDeleteMeeting,
  actionCMFilterMerge,
  actionCMMerge,
  actionCMPatchMeeting,
  actionCMRefresh,
  actionCMViewMerge,
} from './actions';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { apiStatic, apiStaticCanceled, requestError } from 'AurionCR/components';
import { ServiceUserEmployeeProfile } from 'services/user-employee-profiles';
import { addDays, endOfDay, format, startOfDay } from 'date-fns';
import axios from 'axios';
import { getRandomString } from 'utils/other';
import { API_CLINICAL_MEETING_SUBJECTS } from 'services/clinical-meeting-subjects';
import {
  selectCMCalendarFilters,
  selectCMCalendarStatuses,
  selectCMListData,
  selectCMListFilters,
  selectCMListOrder,
  selectCMListPagination,
  selectCMListStatuses,
  selectCMMeetings,
  selectCMStatuses,
  selectCMView,
} from './selectors';
import { API_CLINICAL_MEETING_TYPES } from 'services/clinical-meeting-types';
import {
  API_CLINICAL_MEETINGS,
  apiClinicalMeetings,
  ClinicalMeeting,
  ClinicalMeetingExcel,
  ServiceClinicalMeetings,
} from 'services/clinical-meetings';

import { convertToDate, dateFormat, DateValue } from 'utils/dates';
import {
  dateToDayOfWeek,
  UserEmployeeProfileWorkLogs,
} from 'services/user-employee-profile-work-logs';
import { UserEmployeeProfileAbsence } from 'services/user-employee-profile-absence';
import { UserEmployeeProfileSchedule } from 'services/user-employee-profile-schedules';
import { makeFilterDateRange } from 'utils/app-helpers';
import {
  createFilterDateISO,
  createFilterEquals,
  createFilterEqualsSome,
  createFilterSmartSearch,
  createFilterValueArrayEquals,
  mergeFilters,
} from 'utils/dynamic-helpers';
import { PERMISSION_IDS } from 'services/user-employee-profile-permissions';
import { keyBy } from 'lodash-es';
import { makeFilterPatientsActive } from '../helpers';
import { ModuleExportExcel } from 'modules/export-excel';
import { i18nAppTranslator } from 'modules/i18n';
import { APP_FORMAT_DATE_TIME } from 'configs/const';

interface FetchPatientMeetingsOptions
  extends Pick<ClinicalMeeting, 'userPatientProfileID' | 'meetingFromDateTime'> {}
const fetchPatientMeetings = (options: FetchPatientMeetingsOptions) => {
  const { userPatientProfileID, meetingFromDateTime } = options;
  const range = [
    startOfDay(convertToDate(meetingFromDateTime)),
    endOfDay(convertToDate(meetingFromDateTime)),
  ];

  return ServiceClinicalMeetings.getAllDynamic<
    Pick<
      ClinicalMeeting,
      | 'id'
      | 'userPatientProfileID'
      | 'userEmployeeProfileID'
      | 'arriveToClinicDateTime'
      | 'approveMeeting'
    >
  >({
    select: [
      'id',
      'userPatientProfileID',
      'userEmployeeProfileID',
      'arriveToClinicDateTime',
      'approveMeeting',
    ].join(','),
    filter: mergeFilters(
      `userPatientProfileID=="${userPatientProfileID}"`,
      makeFilterDateRange('meetingFromDateTime', range),
    ).join('&&'),
  });
};

function* handleError(e: any) {
  yield* put(notifyRequestResult(requestError(e, e?.message), 'error'));
}

function* handleSuccess() {
  yield* put(notifyRequestResult());
}

function* loadEmployees() {
  try {
    const {
      data: { value },
    }: {
      data: { value: ICMEmployee[] };
    } = yield call(ServiceUserEmployeeProfile.getAllDynamic, {
      select: [
        'appIdentityUserID',
        'isActive',
        'userPhoto',
        'fullName',
        'firstName',
        'lastName',
        'userCrmProfilePermission.title as department',
        'userCrmProfilePermission.color as color',
      ].join(','),
      filter: mergeFilters(
        createFilterValueArrayEquals('userEmployeeProfilePermissionID', [
          PERMISSION_IDS.DOCTOR,
          PERMISSION_IDS.DIETITIAN,
          PERMISSION_IDS.ACCOUNTANT,
        ]),
      ).join('&&'),
      orderBy: 'isActive desc,firstName,lastName',
    });
    yield* put(
      actionCMMerge({
        employees: value,
      }),
    );
  } catch (e) {
    yield call(handleError, e);
  }
}
function* loadEmployeesCalendarSlots(date: DateValue) {
  try {
    const dayOfWeek = dateToDayOfWeek(date);
    const now = convertToDate(date);
    const dateRange = [startOfDay(now), endOfDay(now)];

    const fetchData = () =>
      ServiceUserEmployeeProfile.getAllDynamic<{
        appIdentityUserID: string;
        workLogs: Pick<UserEmployeeProfileWorkLogs, 'fromTime' | 'toTime' | 'isInClinic'>[];
        absences: Pick<UserEmployeeProfileAbsence, 'id'>[];
        schedules: Pick<UserEmployeeProfileSchedule, 'fromTime' | 'toTime' | 'isInClinic'>[];
      }>({
        filter: 'isActive==true',
        select: [
          'appIdentityUserID',
          `userEmployeeProfileWorkLogs.Where(k => k.isActive == true && dayOfWeek==${dayOfWeek}).Select(k => new { k.id, k.fromTime, k.toTime, k.dayOfWeek, k.isInClinic }) as workLogs`,
          `userEmployeeProfileAbsences.Where(a => a.isActive == true && ${createFilterDateISO(
            'a.eventDate',
            dateRange,
          )}).Select(a => new { a.id, a.eventDate }) as absences`,
          `userEmployeeProfileSchedules.Where(s => s.isActive == true && ${createFilterDateISO(
            's.date',
            dateRange,
          )}).Select(s => new { s.id, s.fromTime, s.toTime, s.date, s.isInClinic }) as schedules`,
        ].join(','),
      });

    const {
      data: { value },
    } = yield* call(fetchData);

    let map = new Map<string, EmployeeTimeSlot[]>();

    value.forEach((item) => {
      if (item.absences.length > 0) {
        map.set(item.appIdentityUserID, []);
      } else if (item.schedules.length > 0) {
        map.set(item.appIdentityUserID, item.schedules);
      } else {
        map.set(item.appIdentityUserID, item.workLogs);
      }
    });

    yield* put(actionCMCalendarSetTimeslots(Object.fromEntries(map.entries())));
  } catch (e) {
    yield call(handleError, e);
  }
}
function* loadMeetingTypes() {
  try {
    const {
      data: { value },
    }: {
      data: { value: ICMMeetingType[] };
    } = yield call(apiStatic.get, API_CLINICAL_MEETING_TYPES.GET_ALL_DYNAMIC, {
      params: {
        select: ['id', 'title', 'color', 'icon', 'meetingTypeKey'].join(','),
        orderBy: 'rank',
      },
    });
    yield* put(
      actionCMMerge({
        meetingTypes: value,
      }),
    );
  } catch (e) {
    yield call(handleError, e);
  }
}
function* loadMeetingSubjects() {
  try {
    const {
      data: { value },
    }: {
      data: { value: ICMMeetingSubject[] };
    } = yield call(apiStatic.get, API_CLINICAL_MEETING_SUBJECTS.GET_ALL_DYNAMIC, {
      params: {
        select: [
          'id',
          'title',
          'color',
          'icon',
          'isCanceledMeeting',
          'isNotShowingUpMeeting',
        ].join(),
        orderBy: 'rank',
      },
    });

    yield* put(
      actionCMMerge({
        meetingSubjects: value,
      }),
    );
  } catch (e) {
    yield* call(handleError, e);
  }
}

function* loadCalendarData() {
  const { date, clinicalMeetingTypeID, clinicalMeetingSubjectIDs, search, isActivePatient } =
    yield* select(selectCMCalendarFilters);
  yield put(actionCMViewMerge({ view: 'calendar', loading: true }));
  try {
    const prepareDateFrom = format(new Date(date), 'yyyy,MM,dd');
    const prepareDateTo = format(addDays(new Date(date), 1), 'yyyy,MM,dd');
    const {
      data: { value },
    }: {
      data: { value: IClinicalMeeting[] };
    } = yield* call(apiStaticCanceled, {
      url: API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC,
      _cancelID: `${API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC}-CALENDAR`,
      params: {
        select: [
          'id',
          'isActive',
          'clinicalMeetingTypeID',
          'clinicalMeetingSubjectID',

          'meetingFromDateTime',
          'meetingToDateTime',

          'userPatientProfileID',
          'new { userPatientProfile.mobilePhone, userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.dateOfBirth, userPatientProfile.shortRemark, userPatientProfile.isActive, userPatientProfile.isTLC, userPatientProfile.onHold, userPatientProfile.onHoldReason, userPatientProfile.doNotProlongTreatment,  userPatientProfile.slowIncreaseWeight, userPatientProfile.changeDosageByDoctorApproval } as userPatientProfile',

          'remarks',

          'userEmployeeProfileID',
          'approveMeeting',
          'completeSessionDateTime',
          'arriveToClinicDateTime',
        ].join(),
        filter: [
          'userEmployeeProfileID!=null',
          'meetingFromDateTime!=null',
          'meetingToDateTime!=null',
          `meetingFromDateTime >= DateTime(${prepareDateFrom},00,00,00)`,
          `meetingToDateTime < DateTime(${prepareDateTo},00,00,00)`,
          createFilterEquals('clinicalMeetingTypeID', clinicalMeetingTypeID),
          createFilterEqualsSome('clinicalMeetingSubjectID', clinicalMeetingSubjectIDs),
          search
            ? createFilterSmartSearch<Components.Schemas.ClinicalMeeting>(
                [
                  'userPatientProfile.firstName',
                  'userPatientProfile.lastName',
                  'userEmployeeProfile.firstName',
                  'userEmployeeProfile.lastName',
                ],
                search,
              )
            : undefined,
          makeFilterPatientsActive(isActivePatient),
        ]
          .filter((item) => item)
          .join('&&'),
      },
    });

    yield* put(actionCMViewMerge({ view: 'calendar', loading: false, data: value }));
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield call(handleError, e);
      yield put(actionCMViewMerge({ view: 'calendar', loading: true }));
    }
  }
}

function* makeListFilters() {
  const {
    date,
    clinicalMeetingTypeID,
    clinicalMeetingSubjectIDs,
    userEmployeeProfileID,
    search,
    isActivePatient,
  } = yield* select(selectCMListFilters);

  return mergeFilters(
    'userEmployeeProfileID!=null',
    'meetingFromDateTime!=null',
    'meetingToDateTime!=null',
    date.length === 2
      ? [
          `meetingFromDateTime >= DateTime(${format(date[0], 'yyyy,MM,dd')},00,00,00)`,
          `meetingToDateTime < DateTime(${format(addDays(date[1], 1), 'yyyy,MM,dd')},00,00,00)`,
        ].join('&&')
      : undefined,
    createFilterEquals('clinicalMeetingTypeID', clinicalMeetingTypeID),
    createFilterEqualsSome('clinicalMeetingSubjectID', clinicalMeetingSubjectIDs),
    createFilterEquals('userEmployeeProfileID', userEmployeeProfileID),
    search
      ? mergeFilters(
          createFilterSmartSearch<Components.Schemas.ClinicalMeeting>(
            ['userPatientProfile.firstName', 'userPatientProfile.lastName'],
            search,
          ),
          createFilterSmartSearch<Components.Schemas.ClinicalMeeting>(
            ['userEmployeeProfile.firstName', 'userEmployeeProfile.lastName'],
            search,
          ),
        ).join('||')
      : undefined,
    makeFilterPatientsActive(isActivePatient),
  ).join('&&');
}
function* makeListOrder() {
  const { field, order } = yield* select(selectCMListOrder);

  return field && order ? `${field} ${order}` : undefined;
}

function* fetchListData(options: { skipPagination: boolean }) {
  const { skipPagination } = options;

  const { take, skip } = yield* select(selectCMListPagination);

  const filters = yield* makeListFilters();
  const orderBy = yield* makeListOrder();

  let params = {
    select: [
      'id',
      'clinicalMeetingTypeID',
      'clinicalMeetingSubjectID',
      'isActive',

      'meetingFromDateTime',
      'meetingToDateTime',

      'userPatientProfileID',
      'new { userPatientProfile.mobilePhone, userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.dateOfBirth, userPatientProfile.shortRemark, userPatientProfile.isActive, userPatientProfile.isTLC, userPatientProfile.onHold, userPatientProfile.onHoldReason, userPatientProfile.doNotProlongTreatment, userPatientProfile.slowIncreaseWeight, userPatientProfile.changeDosageByDoctorApproval } as userPatientProfile',

      'userEmployeeProfileID',
      'approveMeeting',
      'remarks',
      'completeSessionDateTime',
      'arriveToClinicDateTime',

      'userPatientProfileSessionID',
      'new { userPatientProfileSession.notebook.labelKey } as notebook',

      'clinicalMeetingActivities.count() as activities',
      'userPatientProfile.userPatientProfileSubscriptions.OrderByDescending(z => z.endDate).Take(1).Select(k => new {k.endDate, k.subscription.labelKey as title}) as subscriptions',
    ].join(','),
    filter: filters,
    count: true,
    take: skipPagination ? undefined : take,
    skip: skipPagination ? undefined : skip,
    orderBy: orderBy,
  };

  const {
    data: { value, count },
  }: {
    data: { value: IClinicalMeetingListItem[]; count: number };
  } = yield call(apiStaticCanceled, {
    url: API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC,
    _cancelID: `${API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC}-CALENDAR`,
    params,
  });

  return { value, count };
}
function* loadListData() {
  yield* put(actionCMViewMerge({ view: 'list', loading: true }));
  try {
    const { value, count } = yield* fetchListData({ skipPagination: false });

    yield* put(
      actionCMViewMerge({
        view: 'list',
        loading: false,
        data: value,
        dataCount: count,
      }),
    );
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield* call(handleError, e);
      yield* put(actionCMViewMerge({ view: 'list', loading: false }));
    }
  }
}

function* refresh(action: ReturnType<typeof actionCMRefresh>): any {
  const { refreshAnyway, refreshScroll } = action.payload;
  const { init, view } = yield* select(selectCMStatuses);
  const { loading: loadingList } = yield* select(selectCMListStatuses);
  const { loading: loadingCalendar } = yield* select(selectCMCalendarStatuses);

  if (!init) {
    return;
  }

  if (view === 'calendar') {
    if (!loadingCalendar || refreshAnyway) {
      yield* put(actionCMViewMerge({ view: 'calendar', dialogDayViewItems: null }));

      yield* call(loadCalendarData);
    }
  } else if (view === 'list') {
    if (!loadingList || refreshAnyway) {
      yield* call(loadListData);
      if (refreshScroll) {
        yield* put(
          actionCMViewMerge({
            view: 'list',
            triggerScrollUpdate: getRandomString(),
          }),
        );
      }
    }
  }
}
function* refreshActivities() {
  yield* put(actionCMViewMerge({ view: 'list', isRefreshingActivities: true }));
  try {
    const { value } = yield* fetchListData({ skipPagination: false });

    const mapValue = keyBy(value, 'id');

    const currentList = yield* select(selectCMListData);

    const newValue = currentList.map((item) => {
      const newItem = mapValue[item.id];
      if (!newItem) {
        return item;
      }
      return { ...item, ...newItem };
    });

    yield* put(
      actionCMViewMerge({
        view: 'list',
        isRefreshingActivities: false,
        data: newValue,
      }),
    );
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield* call(handleError, e);
      yield* put(actionCMViewMerge({ view: 'list', isRefreshingActivities: false }));
    }
  }
}

function* init(): any {
  const { init, loading } = yield* select(selectCMStatuses);
  const view = yield* select(selectCMView);
  if (!init && !loading) {
    yield* put(actionCMMerge({ loading: true }));
    yield* all([call(loadEmployees), call(loadMeetingTypes), call(loadMeetingSubjects)]);
    yield* put(actionCMMerge({ loading: false, init: true }));
  }
  const { date } = yield* select(selectCMCalendarFilters);
  if (view === 'calendar' && date) {
    yield* call(loadEmployeesCalendarSlots, date);
  }
  yield* put(actionCMRefresh({ refreshAnyway: false, refreshScroll: true }));
}

function* deleteMeeting({ payload: { id } }: ReturnType<typeof actionCMDeleteMeeting>) {
  if (id) {
    yield* put(actionCMMerge({ loading: true, confirmDeleteMeeting: null, editMeeting: null }));
    try {
      yield* call(ServiceClinicalMeetings.delete, { id });
      yield call(handleSuccess);
      yield put(actionCMRefresh({ refreshAnyway: true, refreshScroll: true }));
    } catch (e) {
      yield call(handleError, e);
    }
    yield put(actionCMMerge({ loading: false }));
  }
}

function* sagaPatchMeeting({ payload }: ReturnType<typeof actionCMPatchMeeting>): any {
  const meeting = payload.newMeeting;
  const { oldMeeting } = payload;

  yield* put(actionCMMerge({ loading: true }));

  const fieldsThatShouldUpdate: (keyof ClinicalMeeting)[] = [
    'arriveToClinicDateTime',
    'approveMeeting',
  ];

  const shouldUpdateRest = fieldsThatShouldUpdate.some((field) => {
    return field in meeting;
  });

  try {
    if (shouldUpdateRest) {
      const meetings = yield* select(selectCMMeetings);
      const targetMeeting = meetings.find(({ id }) => id === meeting.id);

      if (!targetMeeting) {
        throw new Error('target-meeting-not-found');
      }
      // find all meetings with the same patient;

      const { userPatientProfileID, meetingFromDateTime } = targetMeeting;

      const {
        data: { value: allPatientMeetings },
      } = yield* call(fetchPatientMeetings, { userPatientProfileID, meetingFromDateTime });

      const restMeetings = allPatientMeetings.filter((meeting) => {
        return meeting.id !== targetMeeting.id;
      });

      const restPayload = Object.fromEntries(
        fieldsThatShouldUpdate.map((field) => [field, meeting[field]]),
      );

      yield* all(
        restMeetings.map(function* (item) {
          const newData = {
            id: item.id,
            userPatientProfileID: item.userPatientProfileID,
            userEmployeeProfileID: String(item.userEmployeeProfileID),
            ...restPayload,
          };

          const res = yield* put(
            apiClinicalMeetings.endpoints.updateClinicalMeetingWithLog.initiate({
              initData: item,
              formData: newData,
              remark: i18nAppTranslator.tp('updates-by-employee'),
              remarkForPatientCallStatusID: undefined,
            }),
          );

          yield* call(res.unwrap);
        }),
      );
    }

    const res = yield* put(
      apiClinicalMeetings.endpoints.updateClinicalMeetingWithLog.initiate({
        formData: meeting,
        initData: oldMeeting,
        remark: i18nAppTranslator.tp('updates-by-employee'),
        remarkForPatientCallStatusID: undefined,
      }),
    );

    yield* call(res.unwrap);

    yield* call(handleSuccess);
    yield* put(actionCMRefresh({ refreshAnyway: true, refreshScroll: false }));
  } catch (e) {
    yield* call(handleError, e);
  }
  yield* put(actionCMMerge({ loading: false }));
}

function* changeView({ payload: view }: ReturnType<typeof actionCMChangeView>) {
  yield put(actionCMMerge({ view }));
  yield put(actionCMRefresh({ refreshAnyway: false, refreshScroll: true }));

  if (view === 'calendar') {
    const { date } = yield* select(selectCMCalendarFilters);

    if (date) {
      yield* call(loadEmployeesCalendarSlots, date);
    }
  }
}

function* watchFilters(action: ReturnType<typeof actionCMFilterMerge>) {
  const payload = action.payload;

  if (payload.view === 'calendar' && payload.date) {
    yield* call(loadEmployeesCalendarSlots, payload.date);
  }
}

function* sagaExportToExcel() {
  yield* put(actionCMMerge({ loading: true }));
  const filters = yield* makeListFilters();
  const orderBy = yield* makeListOrder();

  try {
    const {
      data: { value },
    } = yield* call(ServiceClinicalMeetings.getExcelData, {
      filters: filters || '',
      orderBy,
    });

    const title = [i18nAppTranslator.t('clinical-meetings'), dateFormat(new Date())].join('__');

    const { wb_out } = yield* call(ModuleExportExcel.export, {
      columns: [
        {
          title: i18nAppTranslator.t('clinical-meeting-type'),
          field: '',
          valueTemplate: (data: ClinicalMeetingExcel) => data.clinicalMeetingType.title || '',
        },
        {
          title: i18nAppTranslator.t('clinical-meeting-subject'),
          field: '',
          valueTemplate: (data: ClinicalMeetingExcel) => data.clinicalMeetingSubject.title || '',
        },
        {
          title: i18nAppTranslator.t('meeting-from-date-time'),
          field: 'meetingFromDateTime',
          type: 'date' as const,
          format: APP_FORMAT_DATE_TIME,
        },
        {
          title: i18nAppTranslator.t('meeting-to-date-time'),
          field: 'meetingToDateTime',
          type: 'date' as const,
          format: APP_FORMAT_DATE_TIME,
        },
        {
          title: i18nAppTranslator.t('patient'),
          field: '',
          valueTemplate: (data: ClinicalMeetingExcel) => data.userPatientProfile.fullName || '',
        },
        {
          title: i18nAppTranslator.t('employee'),
          field: '',
          valueTemplate: (data: ClinicalMeetingExcel) => data.userEmployeeProfile.fullName || '',
        },
        {
          title: i18nAppTranslator.t('activities'),
          field: 'activities',
        },
        {
          title: i18nAppTranslator.t('subscription-name'),
          field: '',
          valueTemplate: (data: ClinicalMeetingExcel) => data.subscription?.title || '',
        },
        {
          title: i18nAppTranslator.t('program-info-end-date'),
          field: '',
          valueTemplate: (data: ClinicalMeetingExcel) => data.subscription?.endDate || '',
          type: 'date' as const,
        },
        {
          title: i18nAppTranslator.t('remarks'),
          field: 'remarks',
        },
        {
          title: i18nAppTranslator.t('approve-meeting'),
          field: 'approveMeeting',
          type: 'boolean' as const,
        },
        {
          title: i18nAppTranslator.t('visit-in-clinic'),
          field: 'arriveToClinicDateTime',
          type: 'date' as const,
          format: APP_FORMAT_DATE_TIME,
        },
        {
          title: i18nAppTranslator.t('visit'),
          field: 'completeSessionDateTime',
          type: 'date' as const,
          format: APP_FORMAT_DATE_TIME,
        },
      ],
      data: value,
      settings: {
        title,
      },
    });

    yield* call(ModuleExportExcel.save, { wb_out, name: title });
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield* put(actionCMMerge({ loading: false }));
  }
}

export const sagasClinicalMeetings = [
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.INIT, init),
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.REFRESH, refresh),
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.REFRESH_ACTIVITIES, refreshActivities),
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.PATCH_MEETING, sagaPatchMeeting),
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.DELETE_MEETING, deleteMeeting),
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.CHANGE_VIEW, changeView),
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.FILTER_MERGE, watchFilters),
  takeLatest(CLINICAL_MEETING_ACTION_TYPES.EXPORT_TO_EXCEL, sagaExportToExcel),
];
